import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import {
	AnonymousStudentProfileSynced,
	AnonymousStudentProfileUpdated,
	AnonymousStudentStateChanged
} from '../../interfaces';

export class AnonymousStudentEventBroadcaster {
	constructor(private eventAggregationService: IEventAggregationService) {}

	public broadcastStudentProfileSyncedEvent(event: AnonymousStudentProfileSynced): void {
		this.eventAggregationService.publishTo(AnonymousStudentProfileSynced.EventType, event);
	}

	public broadcastProfileUpdatedEvent(event: AnonymousStudentProfileUpdated): void {
		this.eventAggregationService.publishTo(AnonymousStudentProfileUpdated.EventType, event);
	}

	public broadcastStateChangedEvent(event: AnonymousStudentStateChanged): void {
		this.eventAggregationService.publishTo(AnonymousStudentStateChanged.EventType, event);
	}
}
